function getElementRoot(realId) {
    const embeddedElement = document.querySelector(`[flow-id="${realId}"]`);
    
    return embeddedElement ? embeddedElement.shadowRoot : document;
}

let noFill = false;

function fillFinalInput({ event, selector, value }) {
    setTimeout(() => {
        if (noFill) return;
        
        const realId = event.detail.flowID;
        const elementRoot = getElementRoot(realId);
        const targetInput = elementRoot.querySelector(selector);
        
        if (targetInput) {
            targetInput.value = value;
            console.log('FILLED');
        } else {
            console.log('NO TARGET INPUT');
        }
    }, 100);
}

window.addEventListener('heyflow-screen-view', (event) => {
    if (event.detail.screenName === 'e1-or-e2') {
        fillFinalInput({ 
            event, 
            selector: 'input[data-label="E Employee result"]', 
            value: 'E1 or E2 visa options'
        });
    }
    if (event.detail.screenName === 'i130-or-k1') {
        fillFinalInput({ 
            event, 
            selector: 'input[data-label="i130 or K1 result"]', 
            value: 'i130 or K1 success'
        });
    }
    if (event.detail.screenName === 'k1') {
        fillFinalInput({ 
            event, 
            selector: 'input[data-label="K1 result"]', 
            value: 'K1 success'
        });
    }
    if (event.detail.screenName === 'sorry-screen') {
        fillFinalInput({ 
            event, 
            selector: 'input[data-label="Quiz result"]', 
            value: 'Unsuccessful result'
        });
    }
});

function autoSubmit(screenName) {
    const devOrigin = "https://app.heyflow.com";
    const screenNames = ['sorry-screen', 'i130-or-k1', 'k1'];
    if (screenNames.includes(screenName) && window.location.origin !== devOrigin) {
        const buttonText = 'submit-heyflow-quiz-result';
        const xpath = `//section[@name='${screenName}']//button[contains(., '${buttonText}')]`
        const button = document.evaluate(xpath, document, null, XPathResult.ANY_TYPE, null ).iterateNext();
        if(button instanceof HTMLButtonElement) {
            const container = document.querySelector(`section[name="${screenName}"]`);
            setTimeout(() => {
                const newButton = button.cloneNode(true);
                newButton.style.opacity = 0;
                container.appendChild(newButton);
                newButton.click();
                setTimeout(() => {
                    container.removeChild(newButton);
                }, 5000)
            }, 1500)
        } 
    }
    
}
function autoSubmitListener(event) {
    autoSubmit(event.detail.screenName)
}
window.addEventListener('heyflow-screen-view', autoSubmitListener);

function isDev() {
  const devOrigin = "https://app.heyflow.com";
  return window.location.origin === devOrigin;
}

let valuesHistory = {};

window.addEventListener("heyflow-change", (event) => {
  valuesHistory = event.detail.fieldsSimple || {};
});

function customAsyleeRedirect(event) {
  const nextPage = document.querySelector(
    'section[name="asylee-status-details"]',
  );
  const value = valuesHistory["What is your current immigration status?"];
  const flowID = event.detail.flowID;
  const screenName = event.detail.screenName;
  if (value === "Asylee" && screenName === "visa-list-dropdown" && !isDev()) {
    setTimeout(() => {
      window.heyflow[flowID].navigation.navigate("go", nextPage.id, null, {
        noValidate: true,
      });
    }, 0);
  }
}

window.addEventListener("heyflow-screen-leave", customAsyleeRedirect);

function customJ1Redirect(event) {
  const nextPage = document.querySelector(
    'section[name="j1-status-details"]',
  );
  const value = valuesHistory["What is your current immigration status?"];
  const flowID = event.detail.flowID;
  const screenName = event.detail.screenName;
  if (value === "J" && screenName === "visa-list-dropdown" && !isDev()) {
    setTimeout(() => {
      window.heyflow[flowID].navigation.navigate("go", nextPage.id, null, {
        noValidate: true,
      });
    }, 0);
  }
}

window.addEventListener("heyflow-screen-leave", customJ1Redirect);
